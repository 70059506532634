import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IComboBox } from 'src/components/ComboBox/ComboBox';
import { onFormShowChange } from 'src/store/actions/PassengersShiftActionType';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { IRootReducer } from 'src/store/reducers';
import { passengerSelector } from 'src/store/selectores/passengerShiftsSelectores';
import {
    apiCallStatusesSelector,
    displayPlacementFormAction,
    displayPlacementFormSelector,
    providerClientsSelector,
    screenClientCodeSelector,
    selectedPassengersSelector,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { removeDuplicates } from 'src/utilis/utilis';
import { PASSENGER_ID_INDEX } from 'src/store/slices/manualOrders/helpers';
import moment, { Moment } from 'moment';
import { IDateRange } from 'src/types/global';
import { userDetailsSelector } from 'src/store/slices/common/commonSlice';
import { IDepartment } from 'src/types/line';
import { initialState } from '../components/PlacemenTransportation/context';
import usePlacement from './usePlacement';
import { getWeekStartAndEndDates } from '../../../utilis/utilis';
import { ShiftType } from '../types';
import { buildPlacementShiftObj } from '../utilis';

const useCitiesFilter = (isDisabled?: boolean) => {
    const allPassengers = useSelector((state: IRootReducer) => passengerSelector(state));
    const { t } = useTranslation();

    // const [cities, setCities] = useState();
    const cities: string[] = useMemo(() => {
        try {
            const result = removeDuplicates(
                allPassengers.map((p) => p.city),
                true
            );
            return Array.isArray(result) ? result : [];
        } catch (err) {
            console.log(err);
            return [];
        }
    }, [allPassengers]);

    const [cityFilterInput, setCityFilterInput] = useState<string>('');
    const [selectedCity, setSelectedCity] = useState('');

    const cityFilterProp: IComboBox = useMemo(
        () => ({
            options: cities,
            style: { width: '100%', backgroundColor: 'white' },
            getOptionLabel: (option: string) => option,
            renderInput: (params) => (
                <TextField {...params} label={t('city')} size="small" variant="outlined" />
            ),
            autoSelect: true,
            disabled: isDisabled,
            autoComplete: true,
            inputValue: cityFilterInput,
            value: selectedCity,
            onInputChange: (event, newInputValue) => {
                setCityFilterInput(newInputValue);
            },
            onChange: (event: React.ChangeEvent<{}>, newValue: string | undefined) => {
                setSelectedCity(newValue || '');
            },
        }),
        [cities, cityFilterInput, isDisabled, selectedCity, t]
    );

    return { selectedCity, cityFilterProp };
};

/**
 * Returns a set of functions that can be used to control the placement form.
 * @returns {object} - A set of functions that can be used to control the placement form.
 */
const usePlacementFormUtils = () => {
    const tkDispatch = useAppDispatch();
    const dispatch = useDispatch();

    // const { setPlacment } = usePlacement();

    const dispatchFormClosingActions = useCallback(
        (handleClose?: Function) => {
            // setPlacment({ ...initialState });
            dispatch(onFormShowChange(false));
            tkDispatch(displayPlacementFormAction(false));

            if (handleClose) {
                handleClose();
            }
        },
        [dispatch, tkDispatch]
    );

    return { onClickClose: dispatchFormClosingActions };
};

/**
 * Returns a function that dispatches the actions to display the form.
 * @returns None
 */
const useClickOpen = () => {
    const dispatch = useDispatch();
    const tkDispatch = useAppDispatch();

    const dispatchActionsToDisplayForm = useCallback(() => {
        dispatch(
            onFormShowChange({
                isOpen: true,
            })
        );
        tkDispatch(displayPlacementFormAction(true));
    }, [dispatch, tkDispatch]);

    return dispatchActionsToDisplayForm;
};

const useFormOpenStateSync = () => {
    const isFormOpen: boolean = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.ui.form.isOpen
    );
    const dispatch = useAppDispatch();
    const displayPlacementForm = useRootAppSelector(displayPlacementFormSelector);

    useEffect(() => {
        if (isFormOpen && !displayPlacementForm) {
            dispatch(displayPlacementFormAction(true));
        }
    }, [dispatch, displayPlacementForm, isFormOpen]);
};

const useSwitchingLocation: () => [boolean, React.Dispatch<React.SetStateAction<boolean>>] = () => {
    const getPassengersForTableReqStatus = useRootAppSelector((state) =>
        apiCallStatusesSelector(state, 'tableGetPassengerShifts')
    );
    const selectedClientCode = useRootAppSelector(screenClientCodeSelector);

    const [isSwitching, setIsSwitchingClient] = useState(false);

    useEffect(() => {
        const APPROX_TIME_TO_RENDER_TABLE_WITH_DATA = 200;
        let timeout: NodeJS.Timeout | null = null;

        if (getPassengersForTableReqStatus === 'SUCCESS' && selectedClientCode) {
            timeout = setTimeout(() => setIsSwitchingClient(false), APPROX_TIME_TO_RENDER_TABLE_WITH_DATA);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [getPassengersForTableReqStatus, selectedClientCode]);

    return [isSwitching, setIsSwitchingClient];
};

// const C = 4;
// const firstDayOfWeek = 2;
const currDate = new Date();

const useFirstDayOfWeekSelectors: () => {
    firstDayOfWeek: number;
} = () => {
    const selectedClientCode = useRootAppSelector(screenClientCodeSelector);
    const firstDayOfWeek =
        useRootAppSelector(providerClientsSelector).find(
            (client) => String(client.accountCode) === selectedClientCode
        )?.firstDayOfWeek || 0;

    return { firstDayOfWeek };
};

const useFirstDayOfWeekSetting = () => {
    const { firstDayOfWeek } = useFirstDayOfWeekSelectors();
    // console.log({ firstDayOfWeek: T, currDayNum: C, daysToSubtract });
    const [selectedWeek, setSelectedWeek] = useState<IDateRange>({
        startDate: new Date(),
        endDate: new Date(),
    });

    useEffect(() => {
        if (typeof firstDayOfWeek !== 'number') return;

        // const start = getStartDate(firstDayOfWeek, moment(currDate).day(), currDate);
        const { start: startDate, end: endDate } = getWeekStartAndEndDates(firstDayOfWeek, currDate);

        setSelectedWeek({
            startDate,
            endDate,
        });
    }, [firstDayOfWeek]);

    return [selectedWeek, setSelectedWeek] as const;
};

export const manualOrdersCommonHooks = {
    usePlacementFormUtils,
    useCitiesFilter,
    useFormOpenStateSync,
    useClickOpen,
    useSwitchingLocation,
    useFirstDayOfWeekSetting,
    useFirstDayOfWeekSelectors,
};
