import React, { CSSProperties, FunctionComponent } from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';

// import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';

import { v4 as GuidService } from 'uuid';
import { CircularProgress, MenuItem } from '@mui/material';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customSelect: {
        '&.Mui-disabled': {
            '&.MuiSelect-outlined': {
                border: 0,
            },
            '&.MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '& .MuiSelect-select': {
            backgroundColor: 'white',
            '&:focus': {
                backgroundColor: 'white',
            }
        }

    },
}));

export interface MenuItemProps {
    name: string;
    value: string | number | undefined;
    disabled?: boolean;
    child?: JSX.Element;
    sx?: any;
}

export interface IProps extends SelectProps {
    labalName: string;
    formControlProp: FormControlProps;
    menueItem: Array<MenuItemProps>;
    shrink?: boolean;
    isLoading?: boolean;
}

export const DefaultMenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
} as const;

export const LoadingDropdown: React.FC<{ style?: CSSProperties }> = ({ }) => {
    return (
        <div>
            <div
                style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    border: '1px solid rgba(197, 197, 197, 0.87)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px 0',
                }}
            >
                <CircularProgress size={30} sx={{ color: 'gray' }} />
            </div>
        </div>
    );
};

const DropDown: FunctionComponent<IProps> = ({
    labalName,
    formControlProp,
    menueItem,
    shrink = false,
    inputProps = {},
    isLoading = false,
    ...selectedElemnetProp
}: IProps) => {
    const guid = GuidService();
    const classes = useStyles();

    return (
        <FormControl {...formControlProp}>
            {isLoading ? (
                <LoadingDropdown />
            ) : (
                <>
                    <InputLabel className={classes.customSelect} id={guid}>
                        {labalName}
                    </InputLabel>
                    <Select
                        defaultValue=""
                        className={classes.customSelect}
                        {...selectedElemnetProp}
                        {...(inputProps.InputLabelProps as any)}
                        labelId={guid}
                        label={labalName}
                        MenuProps={{ ...(selectedElemnetProp.MenuProps || {}), ...DefaultMenuProps }}
                    >
                        {menueItem.map((d: MenuItemProps) => (
                            <MenuItem
                                disabled={d.disabled}
                                // sx={{ display: 'none' }}
                                sx={d.sx}
                                key={d.value}
                                value={d.value}
                            >
                                {d.child || d.name}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )}
        </FormControl>
    );
};

export default DropDown;
