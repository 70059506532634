import moment from 'moment';
import React from 'react';
import useProxyApi from 'src/adrianTodosAndUtils/useProxyApi';
import { getVisasExcelReport } from 'src/api/api';
import { RELATIVE } from 'src/constants/dates';
import { setErrorMessage } from 'src/store/actions/loginAction';
import { useAppDispatch, useAppSelector, useRootAppSelector } from 'src/store/hooks';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { allFiltersSelector, clientIdSelector } from 'src/store/selectores/reportsSelectors';

const downloadExcelFile = (fileData: number[]) => {
    const arrayBuffer = new Uint8Array(fileData).buffer;
    const blob = new Blob([arrayBuffer], { type: 'application/vnd.ms-excel' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'visas-report.xls';
    link.click();
};

const useVisasReportExcelExport = () => {
    const dispatch = useAppDispatch();

    const clientCode = useAppSelector((state) => clientIdSelector(state));

    const currFiltersValues = useRootAppSelector((state) => allFiltersSelector(state).currFiltersValues);

    const token = useRootAppSelector((state) => loginSelector(state).token);

    const { proxyApi } = useProxyApi();

    const [isLoading, setIsLoading] = React.useState(false);

    const handleVisasReport = async () => {
        try {
            setIsLoading(true);

            const response = await proxyApi({
                cb: getVisasExcelReport,
                restParams: {
                    clientCode: clientCode || '',
                    fromDate: moment(currFiltersValues.fromDate).format(RELATIVE),
                    toDate: moment(currFiltersValues.toDate).format(RELATIVE),
                    token: token || '',
                },
            });

            if (response.data.response !== '0') {
                if (response.data.message === 'Data Is Empty') {
                    setIsLoading(false);
                    dispatch(setErrorMessage('לא ניתן לייצא דוח ללא נתונים'));
                    return;
                }
                throw new Error(JSON.stringify(response.data));
            }

            downloadExcelFile(response.data.fileData || []);

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            dispatch(setErrorMessage('שגיאה בייצוא דו"ח ויזות לאקסל'));
        }
    };

    return {
        handleVisasReport,
        isLoading,
    };
};

export default useVisasReportExcelExport;
