import React, { useEffect, useRef, useState } from 'react';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import styled, { css } from 'styled-components';
import { Fab } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { toggleWidgetDrawerAction } from 'src/store/slices/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { selectedReportNameSelector, widgetsUiSelector } from 'src/store/selectores/reportsSelectors';
import { fullWithBorder } from '../../../../style/styledCSS';
import styles from './ReportsSection.style';
import { getTestingBox, getTestingFullBox } from '../../../../style/testing';
import Menu from './Menu/Menu';
import Filters from './Filters/Filters';
import Table from './Table/Table';

const ReportsSection = () => {
    const tableRef = useRef(null);

    const excelExportRef = React.useRef<any>(null);

    const [clinetSideFilters, setClientSideFilters] = useState<CompositeFilterDescriptor | null>(null);

    const tableProps = {
        excelExportRef,
        tableRef,
        setClientSideFilters,
        clinetSideFilters,
    };
    console.log('ReportsSection ', tableProps);

    const selectedReportName = useAppSelector(selectedReportNameSelector);

    useEffect(() => {
        setClientSideFilters(null);
    }, [selectedReportName]);

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <styles.SectionContainer className="section-container">
                <Menu {...tableProps} />
                <Filters {...tableProps} />
                <Table {...tableProps} />
            </styles.SectionContainer>
        </div>
    );
};

export default ReportsSection;
