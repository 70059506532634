/* eslint-disable import/no-cycle */

import { takeLatest, put, select, ForkEffect } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga/index';
import { reqGetPassengersShortDetails, reqSetLine, reqSetPassenger } from 'src/api/linesApi/linesApi';
import {
    GetPassengersShortDetailsReqConfig,
    ReqSetPassengerConfig,
    CreateLineReqParams,
    CreateLineRequestConfig,
    CreateLineResponse,
    GetPassengersShortDetailsResponse,
    ReqSetPassengerResponse,
} from 'src/types/lines/api/types';

import { isDict, stringifyDate } from 'src/utilis/utilis';
import i18n from 'i18next';
import { AxiosRes, FcResponseState, ILinesRequest, WsResponse } from 'src/api/types';
import { RefreshTokenStatus } from 'src/types/login';
import { setErrorCode, setErrorMessage } from 'src/store/actions/loginAction';

import { Severity } from 'src/hooks/useAlert';
import { AxiosResponse } from 'axios';

import { getLines } from 'src/api/api';
import { buildSetLineRequestParams, getPartsFromAddressJson } from 'src/screens/Main/utils';
import { Station } from 'src/screens/Main/components/AddLineForm/types';
import linesRoutes, { RoundTripRes } from 'src/mockServer/routes/linesRoutes';
import { MockRouteResponse } from 'src/types/mockServer/routes.types';
import { setTokenRefreshStatus } from '../actions/loginAction';
import {
    fetchPassengersShortDetailsAction,
    fetchPassengersShortDetailsStatusUpdateAction,
    onSubmitAction,
    onSubmitStatusUpdateAction,
    reqSetNewPassengerAction,
    reqSetNewPassengerStatusUpdateAction,
    stationsSelector,
} from '../slices/lines/linesSlice';
import {
    dispatchGlobalErrorAction,
    genericMakeRequest,
    handleResponseV2,
    handleResponseV3,
    makeRequest,
    handleMockServerResponseV1,
} from './utils/sagasUtils';
import { useAppDispatch } from '../hooks';
import { onRetriveLinesFaild, onRetriveLinesSuccess } from '../actions/LineAction';
import { setAlertConfigAction } from '../slices/common/commonSlice';
import { LineActionType } from '../actions/actionType';
import { ErrorMessagesOld } from '../type';
import { callEmailService } from 'src/api/dataMappers/emailBl';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { isHistoryTypeGuard } from 'src/utilis/typeGuards';
import { IRootReducer } from '../reducers';
import { IEditLine } from 'src/types/line';
import { isAllObjectsContainsCoords } from 'src/screens/Main/hooks/useDirectionsApiLines';

interface IRetrivalLine {
    payload: ILinesRequest;
    type: string;
}

function* retriveLines({ payload }: IRetrivalLine): Iterator<any> {
    try {
        if (!payload) yield put(onRetriveLinesFaild([]));
        const response: any = yield getLines(payload);

        const { data = {} } = response;
        console.log(data);
        if (data?.response === FcResponseState.TokenExpired) {
            setTokenRefreshStatus(RefreshTokenStatus.Invalid);
        }

        if (data?.response === '0') yield put(onRetriveLinesSuccess(data?.data));
        else yield put(onRetriveLinesFaild([]));
    } catch (error) {
        yield put(onRetriveLinesFaild([]));
    }
}

function* fetchPassengersShortDetailsWatcher(action: { payload: GetPassengersShortDetailsReqConfig }): any {
    try {
        const { payload: requestConfig } = action;
        const { response, data, status } = yield makeRequest(() =>
            reqGetPassengersShortDetails(requestConfig)
        );
        yield handleResponseV2({
            status,
            response,
            onSuccess: () =>
                fetchPassengersShortDetailsStatusUpdateAction({
                    status: 'SUCCESS',
                    data,
                }),
            onFailure: () => {
                return fetchPassengersShortDetailsStatusUpdateAction({
                    status: 'FAILED',
                    data: [],
                });
            },
        });
    } catch (error) {
        console.log(error as Error);
        yield put(
            fetchPassengersShortDetailsStatusUpdateAction({
                status: 'FAILED',
                data: [],
            })
        );
        yield put(setErrorMessage((error as Error).message));
    }
}

export interface ISetLinePayload {
    formData: any;
    dbUrl: string;
    token: string;
    isRoundTrip: boolean;
    history: unknown;
    lineCode?: number;
}

function* regSetLineWatcher(action: { payload: ISetLinePayload }): any {
    try {
        // const stations: Station[] = yield select(stationsSelector);
        const { dbUrl, token, formData, history } = action.payload;
        const lineOnEdit: IEditLine | null = yield select(
            (state: IRootReducer) => state.linesSlice.ui.form.lineOnEdit
        );
        const isOnEdit: boolean | null = yield select(
            (state: IRootReducer) => state.linesSlice.ui.form.isOnEdit
        );
        console.log('payload', formData);

        const { stations, endAddress, startAddress } = formData;
        const test = yield select((state: IRootReducer) => state);

        console.log('stations', stations);
        const isAllStationsWithCoords = isAllObjectsContainsCoords([...stations, endAddress, startAddress]);

        const requestConfig = {
            dbUrl,
            requestParams: {
                ...buildSetLineRequestParams(
                    formData,
                    token,
                    stations,
                    lineOnEdit,
                    endAddress,
                    startAddress,
                    isAllStationsWithCoords
                ),
                lineCode: lineOnEdit?.lineCode,
            },
        };

        if (action.payload.isRoundTrip) {
            const response: MockRouteResponse<RoundTripRes> = yield linesRoutes.setRoundTripLineRoute({
                formDataWithStations: {
                    ...formData,
                    stationsLst: stations,
                },
                token,
                dbUrl,
            });

            yield handleMockServerResponseV1({
                response,
                *onSuccess() {
                    // throw new Error("I'm a test error");
                    callEmailService(formData, {
                        startLineCode: response.data.data?.createdLinesCode.startLineCode || '',
                        returnLineCode: response.data.data?.createdLinesCode.endLineCode || '',
                    });

                    yield put(onSubmitStatusUpdateAction({ status: 'SUCCESS' }));
                    yield put(
                        setAlertConfigAction({
                            severity: 'success',
                            message: i18n.t('operationWasSuccessful'),
                        })
                    );

                    if (isHistoryTypeGuard(history)) {
                        // history.push(routesEndpoints.HOME);
                    }

                    // Should be at the button level, not here, but for now it's ok
                    // the success of the creation of the line is not the success of the email sending
                    // so we don't want to stop the user from continuing
                    // however, if the email sending fails, we want to show an error
                },
                *onFailure() {
                    yield put(onSubmitStatusUpdateAction({ status: 'FAILED' }));
                    yield put(
                        setAlertConfigAction({
                            severity: 'error',
                            message: i18n.t(response.data.message || ErrorMessagesOld.GeneralError),
                        })
                    );
                },
            });
        } else {
            const r = yield genericMakeRequest<string>(() => reqSetLine(requestConfig));
            const { status, response, data } = r;
            yield handleResponseV3({
                status,
                response,
                *onSuccess() {
                    callEmailService(formData, {
                        startLineCode: String(data) || '',
                        returnLineCode: '',
                    });

                    yield put(onSubmitStatusUpdateAction({ status: 'SUCCESS' }));

                    yield put(
                        setAlertConfigAction({
                            severity: 'success',
                            message: i18n.t('operationWasSuccessful'),
                        })
                    );
                    if (isHistoryTypeGuard(history)) {
                        // history.push(routesEndpoints.HOME);
                    }
                },
                *onFailure() {
                    yield put(onSubmitStatusUpdateAction({ status: 'FAILED' }));
                    yield put(
                        setAlertConfigAction({
                            severity: 'error',
                            message: i18n.t('operationFailed'),
                        })
                    );
                },
            });
        }
    } catch (error) {
        yield put(onSubmitStatusUpdateAction({ status: 'FAILED' }));
        yield dispatchGlobalErrorAction(error);
    }
}

function* reqSetPassengerWatcher(action: { payload: { requestConfig: ReqSetPassengerConfig } }) {
    try {
        const { requestConfig } = action.payload;
        const { clientCode, token, addressJson, mobilePhone, eMail, ...rest } = requestConfig.requestParams;

        const apiResponse: AxiosResponse<ReqSetPassengerResponse> = yield reqSetPassenger(requestConfig);

        const { status } = apiResponse;
        const { response, passCode } = apiResponse.data;

        if (response === '0' && !passCode) {
            throw new Error('Error - Did not get back passcode');
        }

        yield handleResponseV3({
            status,
            response,
            *onSuccess() {
                yield put(
                    reqSetNewPassengerStatusUpdateAction({
                        status: 'SUCCESS',
                        passengerData: {
                            mobilePhone: mobilePhone || '',
                            email: eMail || '',
                            ...getPartsFromAddressJson(addressJson || ''),
                            ...rest,
                            passCode,
                        },
                    })
                );
            },
            *onFailure() {
                yield put(
                    reqSetNewPassengerStatusUpdateAction({
                        status: 'FAILED',
                    })
                );
            },
            errorMessagesEnum: {
                1: 'טוקן לא תקין - נסע לרענן את העמוד',
                2: 'server-error - params missing',
                4: 'קוד נוסע פנימי קיים',
                99: 'unknown server error',
            },
        });
    } catch (error) {
        yield put(
            reqSetNewPassengerStatusUpdateAction({
                status: 'FAILED',
            })
        );
        // alert((error as Error).message);
        yield put(setErrorMessage((error as Error).message));
    }
}
export default function* lineSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(LineActionType.retriveLines, retriveLines);
    yield takeLatest(onSubmitAction, regSetLineWatcher);
    yield takeLatest(fetchPassengersShortDetailsAction, fetchPassengersShortDetailsWatcher);
    yield takeLatest(reqSetNewPassengerAction, reqSetPassengerWatcher);
}
