import React, { useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Input from 'src/components/commons/Input/Input';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputAdornment } from '@material-ui/core';
import MultipleDropdown from 'src/components/MultipleDropDown/MultipleDropDown';
import { PassengersFilterValuesKeys } from 'src/types/reports/generalReportsTypes';
import TimePicker from 'src/components/TimePicker/TimePickerIndex';
import { FilterTypes } from 'src/store/reducers/reportsReducers/uiReducers';
import { AccordionDetails } from '@mui/material';
import ApplyFiltersBtn from '../../ApplyFiltersBtn/ApplyFiltersBtn';
import s, { styledMui } from '../../Filters.style';
import useDropdownProps from '../../hooks/useDropdownProps';
import useGlobalFilter from '../../hooks/useGlobalFilter';
import ClearFiltersBtn from '../../ClearFiltersBtn/ClearFiltersBtn';
import useDropdownOpenState, { reportsHooks as hooks } from '../../hooks/common';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { PassengersReportDatePicker } from './PassengersReportDatePicker';
import { setPassengersReportDateFilterAction } from 'src/store/slices/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { filtersSelector } from 'src/store/selectores/reportsSelectors';
import { ReportNames } from 'src/store/slices/reports/types';

export interface DefaultAccordionProps {
    setClientSideFilters: Function;
    dateInStore?: string;
    expandConfig?: {
        isExpanded: boolean;
        toggle: any;
    };
}
type Props = DefaultAccordionProps;

const PassengersReportFilters = ({ setClientSideFilters, expandConfig }: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    // -- global filter handling
    const { globalFilterProp } = useGlobalFilter({
        setFilters: setClientSideFilters,
    });
    // -- dropdown props
    const { datePickerProps, multDeptsProps, daysProps } = useDropdownProps({ report: 'passengers' });

    const datesFilter = useAppSelector((state) => state.reports.ui.main.filters.byReport.passengers.dates);
    const fromDateFilter = useAppSelector(
        (state) => state.reports.ui.main.filters.byReport.passengers.fromDate
    );

    const { fromTime, toTime, handleChange: handleTimeChange } = hooks.useTimeInput();

    // const [expand, setExpand] = React.useState(true);
    // const toggleAccordion = () => setExpand((prev) => !prev);

    const { openDropdown, toggleDropdown, dropdownRef } = useDropdownOpenState();

    const getDropdownProps = useCallback(
        (dropdownType: PassengersFilterValuesKeys) => ({
            open: openDropdown === dropdownType,
            onClickFormControl: () => toggleDropdown && toggleDropdown(dropdownType, true),
            refObj: dropdownRef,
        }),
        [dropdownRef, openDropdown, toggleDropdown]
    );

    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

    return (
        <styledMui.FiltersAccordion expanded={expandConfig?.isExpanded} elevation={0}>
            <styledMui.MyAccordionSummary
                expandIcon={expandConfig?.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{
                    onClick: expandConfig?.toggle,
                }}
            >
                <s.AccordionBtnsContainer
                    className="AccordionBtnsContainer"
                    paddingLeft="12px"
                    gtc="repeat(3, 1fr) 120px 120px 80px 80px"
                >
                    <PassengersReportDatePicker
                        isOpen={isDatePickerOpen}
                        currSavedValues={{ days: datesFilter, month: fromDateFilter }}
                        onCancel={() => setIsDatePickerOpen(false)}
                        onConfirm={(newValues: { days: Date[]; month: Date }) => {
                            dispatch(setPassengersReportDateFilterAction(newValues));
                        }}
                        openCalender={() => setIsDatePickerOpen(true)}
                        closeCalender={() => setIsDatePickerOpen(false)}
                        key={`${datesFilter.length}-${fromDateFilter.toISOString()}`} // we want to reset the component when those values change
                    />
                    {/* <DatePicker {...datePickerProps.fromDateProps} openTo="month" /> */}
                    <MultipleDropdown {...daysProps} {...getDropdownProps('days')} />
                    <MultipleDropdown {...multDeptsProps} {...getDropdownProps('departmentCodes')} />
                    <TimePicker
                        time={fromTime}
                        textFieldProps={{ label: 'משעה' }}
                        setTime={(newVal) => handleTimeChange(newVal, FilterTypes.FromTime)}
                    />
                    <TimePicker
                        time={toTime}
                        setTime={(newVal) => handleTimeChange(newVal, FilterTypes.ToTime)}
                        textFieldProps={{ label: 'עד שעה' }}
                    />

                    <ApplyFiltersBtn />
                    <ClearFiltersBtn />
                </s.AccordionBtnsContainer>

            </styledMui.MyAccordionSummary>

            <AccordionDetails sx={{ paddingLeft: '54px' }}>
                <s.AccordionBtnsContainer
                    className="AccordionBtnsContainer"
                    paddingLeft="12px"
                    gtc="repeat(3, 1fr) 120px 120px 80px 80px"
                >
                    <Input
                        {...globalFilterProp}
                        className="reports-free-search"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </s.AccordionBtnsContainer>
            </AccordionDetails>
        </styledMui.FiltersAccordion>
    );
};

export default PassengersReportFilters;
