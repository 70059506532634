import { IPassengerDate, IPassengerShifts } from 'src/types/manualOrders/api.types';
import { RELATIVE } from 'src/constants/dates';
import { DateRange } from 'src/screens/ManualOrder/utilis';
import { dateify, stringifyDate } from 'src/utilis/utilis';

// get all dates between two dates
const getDatesBetween = (startDate: Date, endDate: Date): string[] => {
    const dates: string[] = [];
    const currentDate = startDate;

    while (currentDate <= endDate) {
        dates.push(stringifyDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

const datesWithMissingDates = (oldOrdersForDates: IPassengerDate[], datesRange: DateRange) => {
    const newOrdersForDates: IPassengerDate[] = [];
    const datesInRange: string[] = getDatesBetween(dateify(datesRange.fromDate), dateify(datesRange.toDate));
    // console.log('datesWithMissingDates ', datesInRange, oldOrdersForDates);

    datesInRange.forEach((relativeDate) => {
        const match = oldOrdersForDates.find((order) => order.relativeDate === relativeDate);

        const emptyDate = {
            relativeDate,
            pickupOrders: [],
            dropOrders: [],
            orders: [],
        };

        newOrdersForDates.push(match || emptyDate);
    });

    return newOrdersForDates;
};

const processPassengerForDates = (data: IPassengerShifts[], datesRange: DateRange): IPassengerShifts[] => {
    const result = data.map((passenger) => {
        return {
            ...passenger,
            dates: datesWithMissingDates(passenger.dates, datesRange),
        };
    });

    return result;
};

const manualOrdersBL = {
    processPassengerForDates,
};

export default manualOrdersBL;
