import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    onSetSelectedShift,
    setAsOnlySelectedPassenger,
    setEditShift,
    setIsShiftDeleted
} from 'src/store/actions/PassengersShiftActionType';

import TrashIcon from 'src/screens/PassengerManger/components/Passengers/PassengersGrid/TrashIcon';
import MyEditPencilIcon from 'src/components/Icons/MyEditPencil';
import { Slide } from '@mui/material';
import { IRootReducer } from 'src/store/reducers';
import usePlacement from 'src/screens/ManualOrder/hook/usePlacement';
import styled from 'styled-components';
import _ from 'lodash';
import { useAppDispatch, useRootAppSelector } from 'src/store/hooks';
import { shiftHooks } from 'src/screens/ManualOrder/hook/hooks.Shift';
import {
    apiCallStatusesSelector,
    selectedPassengersIdsSelector,
    setSelectedPassengersAction,
} from 'src/store/slices/manualOrders/manualOrdersSlice';
import { manualOrdersCommonHooks } from 'src/screens/ManualOrder/hook/manualOrdersHooks.common';
import { stringifyDate } from 'src/utilis/utilis';
import * as Styles from '../Shiftcell.Style';
import DeleteModal from './DeleteModal';

interface ShiftProps {
    shiftId?: number;
    startTime: string;
    endTime: string;
    color: string;
    isPast: boolean;
    codes: string[];
    passId: string;
    date: Date;
    fullName: string;
    capsuleId: string;
}

const RotatedText = styled.span`
    transform: rotate(-90deg);
    font-weight: bold;
`;
export const ArrowText: React.FC<{}> = ({ }) => {
    return <RotatedText> ⬅ </RotatedText>;
};

export const ShiftTimeDisplay: React.FC<{
    startTime: string;
    endTime: string;
}> = ({ startTime, endTime }) => {
    if (startTime && endTime) {
        return (
            <p>
                {startTime}
                <ArrowText />
                {endTime}
            </p>
        );
    }
    if (startTime && !endTime) {
        return (
            <p>
                {startTime}
                <ArrowText />
            </p>
        );
    }
    return (
        <p>
            <ArrowText />
            {endTime}
        </p>
    );
};
const actionBtnConfig = { width: '18', height: '18', color: 'white' };

const Shift: FC<ShiftProps> = ({
    startTime = '',
    endTime = '',
    color,
    isPast,
    codes,
    capsuleId,
    passId,
    date,
    fullName,
    shiftId,
}: ShiftProps) => {
    const dispatch = useDispatch();
    const tkDispatch = useAppDispatch();

    const { isTargetedToDelete, onDelete } = shiftHooks.useShiftCapsuleDeletionHelpers();

    const isShiftSelected: boolean = useSelector(
        (state: IRootReducer) => state.passengersShiftReudcer.ui.form.isShiftSelected
    );
    const isOpen: boolean = useSelector((state: IRootReducer) => state.passengersShiftReudcer.ui.form.isOpen);
    const isDeletingOrders = useRootAppSelector((state) =>
        apiCallStatusesSelector(state, 'isDeletingOrders')
    );
    const selectedPassengersIds = useRootAppSelector(selectedPassengersIdsSelector);

    const [isHover, setIsHover] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const { handleOnMouseEnter, handleOnMouseLeave } = shiftHooks.useMultiDeleteTimer(
        {
            date,
            // codes,
            passId,
            startTime,
            endTime,
            shiftId: shiftId || null,
            times: JSON.stringify([startTime, endTime]),
        },
        openDeleteModal
    );

    const dispatchFormOpenActions = manualOrdersCommonHooks.useClickOpen();

    const onClickEdit = () => {
        if (!_.isEqual(selectedPassengersIds, [passId])) {
            // dispatch(setAsOnlySelectedPassenger({ passId }));
            tkDispatch(setSelectedPassengersAction([passId]));
        }

        dispatchFormOpenActions();

        dispatch(
            setEditShift({
                date,
                codes,
                passId,
                pickupTime: startTime,
                dropTime: endTime,
                fullName,
            })
        );
        dispatch(onSetSelectedShift(undefined));
    };

    return (
        <>
            <Slide in appear={false}>
                <Styles.SingleShiftContainer
                    isPast={isPast}
                    formIsOpen={isOpen}
                    isShiftSelected={isShiftSelected}
                    isTargetToDelete={isTargetedToDelete(capsuleId)}
                    color={color}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                >
                    {isHover && !isShiftSelected && (
                        <>
                            <Styles.IconContainer
                                style={{ backgroundColor: 'red' }}
                                onClick={() => {
                                    setOpenDeleteModal(true);
                                }}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            >
                                <TrashIcon {...actionBtnConfig} />
                            </Styles.IconContainer>
                            <Styles.IconContainer right onClick={onClickEdit}>
                                <MyEditPencilIcon {...actionBtnConfig} />
                            </Styles.IconContainer>
                        </>
                    )}
                    <ShiftTimeDisplay startTime={startTime} endTime={endTime} />
                </Styles.SingleShiftContainer>
            </Slide>
            <DeleteModal
                isOpen={openDeleteModal}
                onClose={(val) => {
                    setOpenDeleteModal(val);
                }}
                onDelete={() => {
                    onDelete(
                        {
                            codes,
                            passId,
                            date: stringifyDate(date),
                        },
                        () => {
                            setOpenDeleteModal(false)
                            dispatch(setIsShiftDeleted(true));
                        }
                    );
                }}
                startTime={startTime}
                endTime={endTime}
                date={stringifyDate(date)}
                showSpinner={isDeletingOrders === 'LOADING'}
            />
        </>
    );
};

export default Shift;
