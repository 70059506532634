/* eslint-disable no-plusplus */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import HeaderWithDropdown from 'src/components/HeaderWithDropdown/HeaderWithDropdown';
import {
    fetchShiftTimesAction,
    incrementRefreshPageBtnClickCountAction,
    onFetchDepartmentsAction,
    onSelectClientIdAction,
    onErrorAction,
    toggleWidgetDrawerAction,
    incrementApplyFiltersBtnClickCountAction,
} from 'src/store/slices/reports/reportsSlice';
import { IRootReducer } from 'src/store/reducers';
import { loginSelector } from 'src/store/selectores/loginSelectors';
import { getDepartments } from 'src/api/api';
import useDelay from 'src/hooks/useDelay';
import Alert from 'src/components/commons/Alert/Alert';
import {
    clientIdSelector,
    selectedReportNameSelector,
    selectedReportTypeSelector,
    selectError,
    selectRefreshBtnClickCount,
    widgetsUiSelector,
} from 'src/store/selectores/reportsSelectors';
import { routesEndpoints } from 'src/routes/routes_endpoints';
import { ReportNames } from 'src/store/slices/reports/types';
import { containers } from 'src/Layouts/styles';
import WidgetInProg from 'src/components/WidgetInProg/WidgetInProg';
import WidgetCardWrapper from 'src/components/Wrappers/Widgets/WidgetCardWrapper/WidgetCardWrapper';
import WidgetsContainer from 'src/Layouts/WidgetsContainer/WidgetsContainer';
import { AlertProps, Fab } from '@mui/material';
import useAlert from 'src/hooks/useAlert';
import styled, { css } from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FullContainer from 'src/components/Wrappers/FullContainer';
import { asRootReducer } from 'src/store/utils';
import ReportsSection from './components/ReportsSection/ReportsSection';
import Statistics from './components/widgets/OrdersInfo/StatsWidget';
import WidgetWrapper from './components/widgets/WidgetWrapper';
import { cssStyles, StyledFabContainer } from './styles/ReportScreen.style';
import commonRoutes from 'src/mockServer/routes/commonRoutes';
import { CommonCodes } from 'src/types/mockServer/routes.types';
import { IDepartment } from 'src/types/line';

interface Props { }
const REPORTS_USING_DEPARTMENTS_FILTER = ['shortOrders', 'detailedOrders', 'passengers'];

const useFilteringFetching = () => {
    const dispatch = useAppDispatch();

    const { selectedFcAccount, token } = useSelector((state: IRootReducer) => loginSelector(state));
    const clientCode = useAppSelector((state) => clientIdSelector(state));
    const refreshBtnClickCount = useAppSelector((state) => selectRefreshBtnClickCount(state));
    const selectedReportName: ReportNames = useAppSelector((state) => selectedReportNameSelector(state));

    useEffect(() => {
        const { proxyUrl } = selectedFcAccount || {};
        if (!REPORTS_USING_DEPARTMENTS_FILTER.includes(selectedReportName)) {
            return;
        }
        if (selectedFcAccount && clientCode) {
            // console.log({ clientCode });
            const args = {
                proxyUrl,
                dbUrl: selectedFcAccount.dbUrl,
                token,
                clientCode,
            };
            getDepartments(args).then((res) => {
                const { departments } = res.data;
                dispatch(onFetchDepartmentsAction({ departments }));
                // -- done in order to refresh the report using new departments
                dispatch(incrementApplyFiltersBtnClickCountAction());
            });
            dispatch(
                fetchShiftTimesAction({
                    dbUrl: selectedFcAccount.dbUrl,
                    requestParams: { token, clientCode },
                })
            );
        }
    }, [clientCode, dispatch, selectedFcAccount, token, refreshBtnClickCount, selectedReportName]);
};

const WITH_CLOSING = true;

const MyFab: React.FunctionComponent<{ isOpen: boolean; toggleWidgetDrawer: () => void }> = ({
    isOpen,
    toggleWidgetDrawer,
}): JSX.Element => {
    return (
        <div
            style={{
                display: 'flex',
                alignSelf: 'center',
                position: 'relative',
                bottom: '10px',
                right: '19px',
                zIndex: 99,
            }}
        >
            <StyledFabContainer className="styled-fab-container" isOpen={isOpen as boolean}>
                <Fab onClick={toggleWidgetDrawer} size="small" sx={cssStyles.fabSx}>
                    {isOpen ? (
                        <ArrowBackIosNewIcon sx={cssStyles.closeArrowIconSx} fontSize="small" />
                    ) : (
                        <ArrowForwardIosIcon sx={cssStyles.openArrowIconSx} fontSize="small" />
                    )}
                </Fab>
            </StyledFabContainer>
        </div>
    );
};

const ReportsScreen: FC<Props> = ({ }) => {
    useFilteringFetching();

    const dispatch = useAppDispatch();

    const isOpen = useAppSelector((state) => widgetsUiSelector(asRootReducer(state)).isOpen);

    const { delay, setDelay, setDelayWithTimeout } = useDelay(false);

    const onRefreshClick = () => {
        if (!delay) {
            dispatch(incrementRefreshPageBtnClickCountAction());
            setDelay(true);
            setDelayWithTimeout(false, 1000);
        }
    };

    const headerWithDropdownProps = {
        setClientIdAction: onSelectClientIdAction,
        onRefreshClick,
        routeScreenId: routesEndpoints.REPORTS.SCREEN_ID,
        delay,
        style: { paddingTop: '32px', paddingBottom: '20px' },
    };

    const { alertProps, errorMsg } = useAlert(selectError, onErrorAction);

    const toggleWidgetDrawer = () => {
        dispatch(toggleWidgetDrawerAction());
    };

    return (
        <containers.ScreenContainer className="screen-container">
            <containers.MainSectionContainer className="main-section-container">
                <HeaderWithDropdown {...headerWithDropdownProps} />
                <div
                    style={{ height: '100%', width: '100%', display: 'flex' }}
                    className="reportsSection-and-fab-container"
                >
                    <ReportsSection />
                    {WITH_CLOSING && <MyFab isOpen={isOpen} toggleWidgetDrawer={toggleWidgetDrawer} />}
                </div>
            </containers.MainSectionContainer>
            <WidgetsContainer withClosing>
                <FullContainer style={{ paddingBottom: '40px' }}>
                    <WidgetCardWrapper>
                        <Statistics />
                    </WidgetCardWrapper>
                </FullContainer>
            </WidgetsContainer>
            <Alert {...alertProps}>{errorMsg}</Alert>
        </containers.ScreenContainer>
    );
};

export default ReportsScreen;
// // -- error alert management
